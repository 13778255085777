<template lang='pug'>
	nav.navbar.fixed-top.align-items-center.justify-content-center
		slot(v-if='isMobile')
			.nav-left-absolute
				a.d-block(v-if='me && me.id' href='#' v-b-modal.modalMobileMenu)
					mobile-menu-icon
				locale-switch-simple(v-else :left='true')
			router-link.navbar-logo(tag='a' :to='logoLink')
				.logo
			.nav-right-absolute
				a.ml-4(v-if='!me' href='/login') {{ $t('user.login-button') }}
		slot(v-else)
			router-link.navbar-logo(tag='a' :to='logoLink')
				.logo
			
			.nav-right-absolute
				.d-flex.align-items-center
					locale-switch-simple
					user-dropdown.ml-4(v-if='me')
					a.ml-4(v-else href='/login') {{ $t('user.login-button') }}
</template>


<script>
import UserDropdown from '@/components/Common/UserDropdown'
import LocaleSwitchSimple from '@/components/Common/LocaleSwitchSimple'

import {
	MenuIcon,
	MobileMenuIcon
} from '../components/Svg'

export default {
	components: {
		UserDropdown,
		LocaleSwitchSimple,
		'menu-icon': MenuIcon,
		'mobile-menu-icon': MobileMenuIcon,	
	},
	data() {
		return {
			
		}
	},
	computed: {
        logoLink () {
			if ( !this.me ) return {name: 'index'};
			if ( this.me.isPartner ) return {name: 'offer'};

			return {name: 'projects'};
		},
	}
}
</script>
<style land='scss' scoped>
	.nav-right-absolute {
		position: absolute;
		right: 24px;
	}
	.nav-left-absolute {
		position: absolute;
		left: 24px;
	}
</style>